import React from 'react';
import styled from 'styled-components';
import { TextButton, Headline, Text } from '@oneaudi/unified-web-components';
import { useI18n } from '@oneaudi/i18n-context';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import {
  SYS_BREAKPOINT_MD,
  SYS_SPACE_RELATIVE_2_XL,
  SYS_SPACE_RELATIVE_LG,
  SYS_SPACE_RELATIVE_SM,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import { ArticleIntro } from '../../types';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { ArrowButton } from './ArrowButton';

const StyledImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

const StyledTextContainer = styled.div`
  padding-top: ${SYS_SPACE_RELATIVE_LG};

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding-top: 0;
    place-self: center start;
  }
`;

const StyledBasicTeaserLike = styled.div`
  @media (hover: hover) {
    pointer-events: none;
  }

  @media (hover: none) {
    pointer-events: all;
  }

  .audi-footnote-anchor__text {
    pointer-events: all;
  }

  .teaser-textbutton {
    display: none;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    display: grid;
    grid-gap: ${SYS_SPACE_RELATIVE_2_XL};
    grid-template-columns: repeat(2, 1fr);

    .teaser-textbutton {
      cursor: pointer;
      margin: ${SYS_SPACE_RELATIVE_XL} 0;
      display: inline-flex;
      pointer-events: all;
    }
  }
`;

const StyledHeadline = styled(Headline)`
  .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }

  margin: ${SYS_SPACE_RELATIVE_SM} 0;
`;

const StyledCopy = styled(Text)`
  .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding-bottom: ${SYS_SPACE_RELATIVE_SM};
  }
`;

export interface BasicTeaserLikeProps {
  articleIntro: ArticleIntro;
  onTeaserClick(this: void, articleIntro: ArticleIntro): void;
}

export const BasicTeaserLike: React.FC<BasicTeaserLikeProps> = (props) => {
  const { articleIntro, onTeaserClick } = props;
  const { articleCreationDate } = articleIntro;

  const linkLabel = articleIntro.teaserLinkLabel
    ? articleIntro.teaserLinkLabel
    : useI18n({ id: 'fa.article-teaser.linkCaption' });

  const teaserText = renderTextWithFootnotesReferencesV2(
    enableHTMLEntities(
      articleIntro.teaserTextOverride ? articleIntro.teaserTextOverride : articleIntro.copy,
    ),
  );

  const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as Element;

    if (target.className !== 'audi-j-footnote-reference') {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        onTeaserClick(articleIntro);
        window.location.href = articleIntro.teaserLink || '';
      }
    }
  };

  const handleButtonClickEvent = () => {
    onTeaserClick(articleIntro);
  };

  return (
    <StyledBasicTeaserLike onClick={(e) => handleClickEvent(e)}>
      {articleIntro.teaserImage && (
        <StyledImageContainer>
          <img
            loading="lazy"
            src={articleIntro.teaserImage}
            alt={articleIntro.teaserImageAlt || ''}
          />
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {articleCreationDate && (
          <Text variant={{ order: '2', style: 'normal' }}>{articleCreationDate}</Text>
        )}
        {articleIntro.headline && (
          <StyledHeadline tag="h3" variant={{ type: 'headline', order: '3', weight: 'normal' }}>
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(articleIntro.headline))}
          </StyledHeadline>
        )}
        {teaserText && (
          <StyledCopy variant={{ order: '1', style: 'normal' }}>{teaserText}</StyledCopy>
        )}
        {articleIntro.teaserLink && (
          <>
            <ArrowButton
              smallScreensOnly
              teaserLink={articleIntro.teaserLink}
              linkLabel={linkLabel}
              onClick={handleButtonClickEvent}
            />
            <TextButton
              className="teaser-textbutton"
              href={articleIntro.teaserLink}
              variant="secondary"
              onClick={handleButtonClickEvent}
            >
              {linkLabel}
            </TextButton>
          </>
        )}
        <LegalInfo
          consumptionAndEmissions={
            articleIntro.teaserConsumptionAndEmissionsOverride &&
            articleIntro.teaserConsumptionAndEmissionsOverride.length > 0
              ? articleIntro.teaserConsumptionAndEmissionsOverride
              : articleIntro.consumptionAndEmissions
          }
          disclaimers={
            articleIntro.teaserDisclaimersOverride &&
            articleIntro.teaserDisclaimersOverride.length > 0
              ? articleIntro.teaserDisclaimersOverride
              : articleIntro.disclaimers
          }
        />
      </StyledTextContainer>
    </StyledBasicTeaserLike>
  );
};
