/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Headline } from '@oneaudi/unified-web-components';
import {
  ThemeProvider,
  SYS_SPACE_RELATIVE_MD,
  SYS_SPACE_RELATIVE_LG,
  SYS_SPACE_RELATIVE_3_XL,
  SYS_BREAKPOINT_MD,
  SYS_BREAKPOINT_XL,
  SYS_BREAKPOINT_2_XL,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import { AppContent } from '../../types';
import { useTracking } from '../../utils/useTracking';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';

import { BasicTeaserLike } from './BasicTeaserLike';
import { TileTeaser } from './TileTeaser';

const StyledArticleTeaser = styled.div`
  .audi-footnote-anchor__text {
    font-size: 0.8em;
    font-weight: lighter;
  }

  .top-headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 24px !important;
  }

  ${({ theme }: { theme: DefaultTheme }) => {
    return `
      background-color: ${theme.global.color.canvas.default};
      padding: ${SYS_SPACE_RELATIVE_3_XL} var(--page-margin);
    `;
  }}
`;

export interface StyledTeaserContainerProps {
  hasMarginTop?: boolean;
  maxColumns: number;
}

const StyledTeaserContainer = styled.div<StyledTeaserContainerProps>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;

  ${(props) => {
    return `
      grid-gap: ${SYS_SPACE_RELATIVE_LG} ${SYS_SPACE_RELATIVE_MD};

      @media (min-width: ${SYS_BREAKPOINT_MD}) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(${Math.min(2, props.maxColumns)}, 1fr);
      }


      @media (min-width: ${SYS_BREAKPOINT_XL}) {
        grid-template-columns: repeat(${Math.min(3, props.maxColumns)}, 1fr);
      }

      @media (min-width: ${SYS_BREAKPOINT_2_XL}) {
        grid-template-columns: repeat(${Math.min(4, props.maxColumns)}, 1fr);
      }
    `;
  }}
`;

const StyledHeadline = styled(Headline)`
  margin-block-end: ${SYS_SPACE_RELATIVE_XL};
`;

export const ArticleTeaser: React.FC<AppContent> = (props) => {
  const { headline, headlineTag, articleIntros, maxColumns, theme } = props;
  const singleTeaserMode = Boolean(articleIntros && articleIntros.length === 1);

  const ref = React.useRef(null);
  const tracking = useTracking(
    ref,
    headline,
    articleIntros && articleIntros.length > 0
      ? articleIntros.map((articleIntro) => ({
          elementName: 'article',
          elementValue: `${articleIntro.index + 1}-${articleIntro.headline}`,
        }))
      : [],
    {
      implementer: 37,
    },
  );

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <StyledArticleTeaser ref={ref} data-test-id="article-teaser">
        {headline && (
          <StyledHeadline
            className="top-headline"
            tag={headlineTag || 'h2'}
            variant={{ type: 'headline', order: '1', weight: 'normal' }}
          >
            <UeElement type="text" property="headline" label="Headline">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
            </UeElement>
          </StyledHeadline>
        )}
        {!singleTeaserMode && (
          <StyledTeaserContainer maxColumns={maxColumns}>
            {articleIntros &&
              articleIntros.map((articleIntro, i) => (
                <TileTeaser
                  key={`item-${i}`}
                  articleIntro={articleIntro}
                  onTeaserClick={tracking.sendClickEvent}
                />
              ))}
          </StyledTeaserContainer>
        )}
        {singleTeaserMode && (
          <BasicTeaserLike
            articleIntro={articleIntros![0]}
            onTeaserClick={tracking.sendClickEvent}
          />
        )}
      </StyledArticleTeaser>
    </ThemeProvider>
  );
};
