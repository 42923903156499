import React from 'react';
import styled from 'styled-components';
import { Text, Headline } from '@oneaudi/unified-web-components';
import { SYS_SPACE_RELATIVE_LG, SYS_SPACE_RELATIVE_SM } from '@oneaudi/unified-web-common';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { useI18n } from '@oneaudi/i18n-context';
import { ArticleIntro } from '../../types';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { ArrowButton } from './ArrowButton';

const StyledImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

const StyledTextContainer = styled.div`
  padding-top: ${SYS_SPACE_RELATIVE_LG};

  .headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }

  .copy > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }
`;

const StyledTileTeaser = styled.div`
  @media (hover: hover) {
    pointer-events: none;
  }

  @media (hover: none) {
    pointer-events: all;
  }

  .audi-footnote-anchor__text {
    pointer-events: all;
  }

  text-decoration: none;
`;

export interface TileTeaserProps {
  articleIntro: ArticleIntro;
  onTeaserClick(this: void, articleIntro: ArticleIntro): void;
}

const StyledHeadline = styled(Headline)`
  padding-bottom: ${SYS_SPACE_RELATIVE_SM};
`;

const StyledTitle = styled(Text)`
  padding-bottom: ${SYS_SPACE_RELATIVE_SM};
`;

export const TileTeaser: React.FC<TileTeaserProps> = (props) => {
  const { articleIntro, onTeaserClick } = props;

  const title = articleIntro.articleCreationDate || undefined;

  const linkLabel = articleIntro.teaserLinkLabel
    ? articleIntro.teaserLinkLabel
    : useI18n({ id: 'fa.article-teaser.linkCaption' });

  const teaserText = renderTextWithFootnotesReferencesV2(
    enableHTMLEntities(
      articleIntro.teaserTextOverride ? articleIntro.teaserTextOverride : articleIntro.copy,
    ),
  );

  const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as Element;

    if (target.className !== 'audi-j-footnote-reference') {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        onTeaserClick(articleIntro);
        window.location.href = articleIntro.teaserLink || '';
      }
    }
  };

  return (
    <StyledTileTeaser onClick={handleClickEvent}>
      {articleIntro.teaserImage && (
        <StyledImageContainer>
          <img
            loading="lazy"
            src={articleIntro.teaserImage}
            alt={articleIntro.teaserImageAlt || ''}
          />
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {title && (
          <StyledTitle variant={{ type: 'copy', style: 'normal', order: '2' }}>{title}</StyledTitle>
        )}
        {articleIntro.headline && (
          <StyledHeadline
            className="headline"
            tag="h3"
            variant={{ type: 'headline', weight: 'normal', order: '3' }}
          >
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(articleIntro.headline))}
          </StyledHeadline>
        )}
        {teaserText && (
          <Text className="copy" variant={{ type: 'copy', style: 'normal', order: '1' }}>
            {teaserText}
          </Text>
        )}
        {articleIntro.teaserLink && (
          <ArrowButton
            smallScreensOnly={false}
            teaserLink={articleIntro.teaserLink}
            linkLabel={linkLabel}
            onClick={() => onTeaserClick(articleIntro)}
          />
        )}
        <LegalInfo
          consumptionAndEmissions={
            articleIntro.teaserConsumptionAndEmissionsOverride &&
            articleIntro.teaserConsumptionAndEmissionsOverride.length > 0
              ? articleIntro.teaserConsumptionAndEmissionsOverride
              : articleIntro.consumptionAndEmissions
          }
          disclaimers={
            articleIntro.teaserDisclaimersOverride &&
            articleIntro.teaserDisclaimersOverride.length > 0
              ? articleIntro.teaserDisclaimersOverride
              : articleIntro.disclaimers
          }
        />
      </StyledTextContainer>
    </StyledTileTeaser>
  );
};
