import { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, FalconContent, ArticleIntro } from '../types';
import { debug } from './debugging';

export const INVALID_CONTENT_ERROR = 'Content is invalid.';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as FalconContent;

  if (!content || content.__type !== 'aem-headless' || !isFalconContent(content)) {
    // Throw `InvalidContentError` here if provided content
    // is not valid. This will display as a special error message
    // in the frontend on author environments.
    debug('InvalidContentError/Content is invalid.', content);
    throw new InvalidContentError(INVALID_CONTENT_ERROR);
  }

  // map CF content to internal structure
  return mapFalconContent(content);
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(mainContent: FalconContent): AppContent {
  debug('raw content', mainContent);

  const mappedMainContent: AppContent = {
    headline: mainContent.fields.headline,
    headlineTag: mainContent.fields.headlineTag || 'h2',
    articleIntros: [],
    maxColumns: mainContent.fields.maxColumns ? parseInt(mainContent.fields.maxColumns, 10) : 4,
    theme: mainContent.fields.theme || 'Light',
  };

  if (mainContent.fields.articleIntros) {
    mainContent.fields.articleIntros.forEach((content, index) => {
      const mappedContent: ArticleIntro = {
        index,

        // copied from fa-article-intro START
        headline: content.fields.headline || '',
        copy: content.fields.copy || '',
        theme: content.fields.theme || 'Light',
      };

      if (content.fields.teaserLink && content.fields.teaserLink.path) {
        mappedContent.teaserLink = content.fields.teaserLink.path;
      }

      // teaserLinkUrl has priority over teaserLink
      if (content.fields.teaserLinkUrl) {
        mappedContent.teaserLink = content.fields.teaserLinkUrl;
      }

      if (content.fields.teaserLinkLabel) {
        mappedContent.teaserLinkLabel = content.fields.teaserLinkLabel;
      }

      if (content.fields.teaserImage && content.fields.teaserImage.path) {
        mappedContent.teaserImage = content.fields.teaserImage.path;
      }

      if (content.fields.teaserImageAlt) {
        mappedContent.teaserImageAlt = content.fields.teaserImageAlt;
      }

      if (content.fields.teaserTextOverride) {
        mappedContent.teaserTextOverride = content.fields.teaserTextOverride;
      }

      if (
        content.fields.teaserConsumptionAndEmissionsOverride &&
        content.fields.teaserConsumptionAndEmissionsOverride.length > 0
      ) {
        mappedContent.teaserConsumptionAndEmissionsOverride =
          content.fields.teaserConsumptionAndEmissionsOverride;
      }

      if (
        content.fields.teaserDisclaimersOverride &&
        content.fields.teaserDisclaimersOverride.length > 0
      ) {
        mappedContent.teaserDisclaimersOverride = content.fields.teaserDisclaimersOverride;
      }

      if (content.fields.articleID) {
        mappedContent.articleID = content.fields.articleID;
      }

      if (content.fields.articleFormat) {
        mappedContent.articleFormat = content.fields.articleFormat;
      }

      if (content.fields.articleCredits) {
        mappedContent.articleCredits = content.fields.articleCredits;
      }

      if (content.fields.articleReadingTime) {
        mappedContent.articleReadingTime = content.fields.articleReadingTime;
      }

      if (content.fields.articleCreationDate) {
        mappedContent.articleCreationDate = new Date(
          content.fields.articleCreationDate,
        ).toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          timeZone: 'UTC',
        });
      }

      if (
        content.fields.consumptionAndEmissions &&
        content.fields.consumptionAndEmissions.length > 0
      ) {
        mappedContent.consumptionAndEmissions = content.fields.consumptionAndEmissions;
      }

      if (content.fields.disclaimers && content.fields.disclaimers.length > 0) {
        mappedContent.disclaimers = content.fields.disclaimers;
      }
      // copied from fa-article-intro END

      mappedMainContent.articleIntros?.push(mappedContent);
    });
  }

  debug('mapped content', mappedMainContent);

  return mappedMainContent;
}
